<template>

	<!-- Task card -->
	<a-card
		:bordered="false" class="kanban-card mb-24"
	>
    <span style="font-size: 14px;font-weight: 500;" class="text-md mb-0 a-link cursor-pointer font-bold" @click="open_drawer_request(task)" v-if="task.name">
      #{{task.request_number}} {{ task.name }}
    </span>

<!--		<p v-if="task.description">{{ task.description }}</p>-->

<!--		<a-progress v-if="task.progress" :percent="task.progress.value" :status="task.progress.status" :strokeColor="task.progress.color" :show-info="false" />-->

<!--		<a-row v-if="task.attachments || task.members" type="flex" class="">-->
<!--			<a-col>-->
<!--				<div v-if="task.attachments">-->
<!--					<a-icon type="paper-clip" class="m-0" /> {{ task.attachments }}-->
<!--				</div>-->
<!--			</a-col>-->
<!--			<a-col class="ml-auto">-->
<!--				<a-space v-if="task.members && task.members.length" :size="-8" class="avatar-chips">-->
<!--					<a-avatar :size="24"-->
<!--						v-for="avatar in task.members"-->
<!--						:key="avatar"-->
<!--						:src="avatar" />-->
<!--				</a-space>-->
<!--			</a-col>-->
<!--		</a-row>-->
	</a-card>
	<!-- / Task card -->

</template>

<script>

	export default {
		props: {
			// Task object
			task: Object,

			// Task's board_id
			boardId: [String, Number],
		},
		data() {
			return {
			}
		},
    methods:{
      open_drawer_request(task){
        this.$emit('open_drawer_request', {data:task})
      }
    }
	}

</script>

<style lang="scss">
</style>
